





























































































import { Component, Vue, Watch } from 'vue-property-decorator';

import moment from 'moment';
import { uniqBy } from 'lodash';
import { format, parseISO } from 'date-fns';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import Loading from '@/components/Loading/Loading.vue';
import ContainerInstructionProposal from '@/components/Redaction/ContainerInstructionProposal/ContainerInstructionProposal.vue';
import ContainerSendRedaction from '@/components/Redaction/ContainerSendRedaction/ContainerSendRedaction.vue';
import ContainerQuestion from '../../components/ContainerQuestion/ContainerQuestion.vue';
import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import SimulatedQuestionLoading from './SimulatedQuestionLoading.vue';

import ModalWarningRedaction from '../../components/Modals/ModalWarningRedaction.vue';
import ModalWarningQuestion from '../../components/Modals/ModalWarningQuestion.vue';
import ModalTimeFinishSimulated from '../../components/Modals/ModalTimeFinishSimulated.vue';
import ModalSuccessSimulated from '../../components/Modals/ModalSuccessSimulated.vue';

import ConfigSidebar from '../../mixins/ConfigSidebar';

import SimulationService from '@/services/Simulation/SimulationService';

import ActiveModal from '@/share/Util/ActiveModal';
import { UrlTreatmentSaveVersa } from '@/share/Util/Image/Image';
import ScrollControl from '@/share/Util/ScrollControl/ScrollControl';

import { SIMULATED_CONSTANT } from '../../constants/SimulatedQuestion';
import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import {
  AnsweredQuestion as IAnsweredQuestion,
  ReceivedRedaction as IReceivedRedaction,
  Redaction as IRedaction,
  QuestionSimulated as IQuestionSimulated,
  Option as IOption,
  NewQuestionSimulated as INewQuestionSimulated,
  NewOption as INewOption,
  NewStructureQuestionsSimulated as INewStructureQuestionsSimulated,
  SimulatedDay as ISimulatedDay,
} from '../../interface/ISimulated';

import { Simulated as ISimulated } from '@/services/Simulation/ISimulatedService';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    Tabs,
    Loading,
    SimulatedQuestionLoading,
    ContainerInstructionProposal,
    ContainerSendRedaction,
    ContainerQuestion,
    ButtonMovie,
    Footer,
    ModalTimeFinishSimulated,
    ModalWarningRedaction,
    ModalWarningQuestion,
    ModalSuccessSimulated,
    FeedbackUser,
  },
  mixins: [ConfigSidebar],
})
export default class SimulatedQuestion extends Vue {
  private tabActiveIsRedaction = false;
  private isOpenComponentsInputRedaction = false;
  private isLoadingButtonSend = false;
  private isLoadingPage = false;
  private showedRedactionModal = false;
  private noQuestions = false;

  private selectComponent = SIMULATED_CONSTANT.COMPONENT_QUESTION;
  private dateTimeStartSimulated = '';
  private tabActive = '';
  private clock = '';
  private secondsCounter = 0;
  private totalQuantityQuestions = 0;

  private tabMenu: Array<TabsMenuInterface> = [];

  private redaction: IRedaction = {
    themeID: 0,
    content: '',
  }
  private newQuestions: Record<string, any> = {};
  private answerQuestions: Array<IAnsweredQuestion> = [];

  private setModal = new ActiveModal();
  private setScroll = new ScrollControl();
  private ServiceSimulation= new SimulationService();

  created() {
    this.resetStatusSentSimulatedInStore();
    this.setBreadCrumbs();
    this.getSimulatedQuestions();
  }

  mounted() {
    this.savePreviousPageRouteInStore();
    this.setStartTimeCount();
    window.addEventListener('beforeunload', this.listenReloadPage);
    window.addEventListener('popstate', this.listenClickButtonBackBrowser);
  }

  beforeDestroy() {
    this.storeCommitsDestroy();
    window.removeEventListener('beforeunload', this.listenReloadPage);
    window.removeEventListener('popstate', this.listenClickButtonBackBrowser);
  }

  get infoSimulatedQuestion() {
    return uniqBy(this.$store.getters.infoSimulatedQuestion.results, 'ID');
  }

  get simulatedDayId() {
    return this.infoSimulatedQuestion[this.indexStoreSimulatedDay].ID;
  }

  get indexStoreSimulatedDay() {
    return this.$store.getters.indexSimulatedDay;
  }

  get simulatedDay() {
    return this.indexStoreSimulatedDay === SIMULATED_CONSTANT.INDEX_FIRST_DAY_SIMULATED ? SIMULATED_CONSTANT.FIRST_DAY_SIMULATED : SIMULATED_CONSTANT.SECOND_DAY_SIMULATED;
  }

  get dateResultSimulated() {
    return this.infoSimulatedQuestion.find((simulatedDay: ISimulatedDay) => simulatedDay.post_result_release)?.post_result_release;
  }

  get canGoToPageResult() {
    const dateCurrent = format(new Date(), SIMULATED_CONSTANT.DATE_FORMAT);
    if (this.isFirstDay) return false;
    if (this.dateResultSimulated && !this.isFirstDay) return this.dateResultSimulated < dateCurrent;
    return true;
  }

  get modalTextFirstDay() {
    return this.isWaitingSecondDaySimulated ? `${SIMULATED_CONSTANT.MODAL_TEXT_FIRST_DAY_SUCCESSFUL_SENDING_SIMULATED} ${format(this.initDateSecondDay, 'dd/MM')}.` : SIMULATED_CONSTANT.MODAL_TEXT_SECOND_DAY_AVAILABLE;
  }

  get modalTextSecondDay() {
    if (this.canGoToPageResult || !this.dateResultSimulated) return SIMULATED_CONSTANT.MODAL_TEXT_GO_TO_PAGE_RESULT;
    return `${SIMULATED_CONSTANT.MODAL_TEXT_SECOND_DAY_SUCCESSFUL_SENDING_SIMULATED} ${format(parseISO(this.dateResultSimulated), 'dd/MM')}.`;
  }

  get modalTextSuccessSendSimulated() {
    return this.isFirstDay ? this.modalTextFirstDay : this.modalTextSecondDay;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get isShowAllQuestion() {
    const { formatShowingQuestions } = this.$store.getters;
    return formatShowingQuestions === SIMULATED_CONSTANT.FORMAT_SHOWING_QUESTIONS;
  }

  get isFirstDay() {
    return this.simulatedDay === SIMULATED_CONSTANT.FIRST_DAY_SIMULATED;
  }

  get themeIdRedaction() {
    return this.infoSimulatedQuestion[this.indexStoreSimulatedDay]?.themes ? this.infoSimulatedQuestion[this.indexStoreSimulatedDay]?.themeID : SIMULATED_CONSTANT.THEME_REDACTION_ZERO;
  }

  get userId() {
    return this.$store.getters.profile.idstudent;
  }

  get hoursForMinutes() {
    const convertInHours = this.clock.split(':');
    const calcHourForMinutes = Number(convertInHours[0]) * 60 + Number(convertInHours[1]) + Number(convertInHours[2]) / 60;
    return Number(calcHourForMinutes);
  }

  get idLanguageSelected() {
    const { idLanguageSelected } = this.$store.getters;
    return idLanguageSelected || SIMULATED_CONSTANT.ID_LANGUAGE_NOT_SELECTED;
  }

  get nameDay() {
    return this.isFirstDay ? SIMULATED_CONSTANT.NAME_FIRST_DAY : SIMULATED_CONSTANT.NAME_SECOND_DAY;
  }

  get isTimeout() {
    return !this.hoursForMinutes;
  }

  get dateFormatted() {
    return `${format(new Date(), SIMULATED_CONSTANT.DATE_FORMAT)}Z`;
  }

  get initDateSecondDay() {
    return new Date(this.infoSimulatedQuestion[SIMULATED_CONSTANT.INDEX_SECOND_DAY_SIMULATED]?.init_date);
  }

  get isWaitingSecondDaySimulated() {
    const dateCurrent = new Date();
    if (!this.initDateSecondDay) return true;
    return this.initDateSecondDay > dateCurrent;
  }

  get hasRedactionInSimulatedDay() {
    return this.tabMenu.find((tab: Record<string, any>) => tab.id === SIMULATED_CONSTANT.TAB_REDACTION.id);
  }

  resetStatusSentSimulatedInStore() {
    this.$store.commit('setSimulatedSent', false);
  }

  listenClickButtonBackBrowser(e: any) {
    e.preventDefault();
    this.$router.push({
      name: 'SimulatedQuestions',
      params: {
        id: this.$route.params.id,
      },
    });
  }

  storeCommitsDestroy() {
    this.$store.commit('setSidebar', false);
    this.$store.commit('setSidebarExistence', false);
  }

  async getSimulatedQuestions() {
    try {
      this.isLoadingPage = true;
      const { id } = this.$route.params;
      const response = await this.ServiceSimulation.getSimulated(Number(id), SIMULATED_CONSTANT.PERMISSION_SAVE_GET_DATA_STORE);
      const questions = response.results[this.indexStoreSimulatedDay]?.questions;

      if (questions) {
        this.separateQuestionsByArea(questions);
      } else {
        this.noQuestions = true;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: SIMULATED_CONSTANT.MSG_ERRO_SIMULATED,
        status: 'error',
      });
    } finally {
      this.isLoadingPage = false;
    }
  }

  savePreviousPageRouteInStore() {
    const { to } = this.$store.getters['RouterHistory/routesFromTo'];
    const routerTo = to.fullPath;
    this.$store.commit('savePreviousPageRoute', routerTo);
  }

  listenReloadPage(e: Record<string, any>) {
    e.preventDefault();
    e.returnValue = ''; // suporte para Gecko, Trident, Chrome 34+
    return ''; // suporte para Gecko, WebKit, Chrome <34
  }

  @Watch('isTimeout')
  showTimeoutModal() {
    if (this.isTimeout) this.showModal(SIMULATED_CONSTANT.COMPONENT_MODAL_FINISHED_TIME);
  }

  @Watch('newQuestions')
  startTimer() {
    this.dateTimeStartSimulated = this.dateFormatted;
  }

  @Watch('tabMenu')
  setTotalQuantityQuestions() {
    if (this.tabMenu.findIndex((tab: Record<string, any>) => tab.id === SIMULATED_CONSTANT.TAB_NAME_LANGUAGES) !== -1) {
      this.totalQuantityQuestions -= SIMULATED_CONSTANT.QUANTITY_QUESTIONS_LANGUAGENS_EN_ES;
    }
  }

  separateQuestionsByArea(questions: Record<string, any>) {
    if (questions) {
      const newListSimulated = Object.keys(questions).map((key: string) => {
        this.totalQuantityQuestions += questions[key].length;
        return questions[key];
      });
      this.newQuestions = this.setStructureSimulatedQuestions(newListSimulated);
      this.setTabMenu();
    }
  }

  setTabMenu() {
    let newFilteredTab: Array<TabsMenuInterface> = SIMULATED_CONSTANT.LIST_TAB.filter((tab: TabsMenuInterface) => (tab.id ? this.newQuestions[tab.id].length : ''));

    if (this.infoSimulatedQuestion[this.indexStoreSimulatedDay]?.themes) {
      newFilteredTab = [
        ...newFilteredTab.slice(0, 1),
        { title: 'Redação', id: SIMULATED_CONSTANT.TAB_ID_REDACTION, active: false },
        ...newFilteredTab.slice(1),
      ];
    }

    newFilteredTab[0] = { ...newFilteredTab[0], active: true };
    this.tabMenu = newFilteredTab;
    this.setTabActive();
  }

  setTabActive() {
    this.tabActive = this.tabMenu[0].id || '';

    if (this.tabActive === SIMULATED_CONSTANT.TAB_NAME_LANGUAGES) this.filterQuestionsFromSelectedLanguage(this.newQuestions[this.tabActive]);
  }

  setStructureSimulatedQuestions(listQuestion: Array<Array<IQuestionSimulated>>) {
    const newStructureSimulated = listQuestion.reduce((acc: INewStructureQuestionsSimulated, discipline: Array<IQuestionSimulated>) => {
      discipline.map((question: IQuestionSimulated) => (
        {
          ...question,
          id: question.ID,
          question: question.content,
          options: this.newListOption(question.options),
          response: null,
        }))
        .forEach((question: INewQuestionSimulated) => {
          if (['Língua Portuguesa', 'Literatura', 'Língua Inglesa', 'Língua Espanhola', 'Arte'].includes(question.titleDiscipline)) acc.lc_lt.push(question);

          else if (['História', 'Geografia', 'Filosofia', 'Sociologia'].includes(question.titleDiscipline)) acc.ch_ct.push(question);

          else if (['Química', 'Física', 'Biologia'].includes(question.titleDiscipline)) acc.cn_ct.push(question);

          else acc.mt.push(question);
        });

      return acc;
    }, {
      lc_lt: [],
      ch_ct: [],
      cn_ct: [],
      mt: [],
    });
    return newStructureSimulated;
  }

  newListOption(options: Array<IOption>) {
    const newListOption = options.map((option: IOption) => ({
      ...option, title: option.content, id: option.ID, value: false,
    }));

    return newListOption;
  }

  filterQuestionsFromSelectedLanguage(listQuestion: Array<INewQuestionSimulated>) {
    const { idLanguageSelected } = this.$store.getters;

    this.newQuestions[this.tabActive] = listQuestion.filter((question: INewQuestionSimulated) => (idLanguageSelected === SIMULATED_CONSTANT.ID_LANGUAGE_SPANISH ? question.disciplineID !== SIMULATED_CONSTANT.ID_LANGUAGE_ENGLISH : question.disciplineID !== SIMULATED_CONSTANT.ID_LANGUAGE_SPANISH));
  }

  async sendSimulated() {
    try {
      this.isLoadingButtonSend = true;

      const simulated: ISimulated = {
        userID: this.userId,
        simulID: this.simulatedDayId,
        questions: this.answerQuestions,
        duration: Number(this.hoursForMinutes.toFixed(2)),
        start: this.dateTimeStartSimulated,
        end: `${format(new Date(), SIMULATED_CONSTANT.DATE_FORMAT)}Z`,
        language: this.idLanguageSelected,
        day: String(this.simulatedDay),
      };

      if (this.redaction.content || this.redaction.URL) simulated.redaction = this.redaction;

      const response = await this.ServiceSimulation.saveSimulated(simulated);

      if (response.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.$store.commit('setSimulatedSent', true);
        this.showModal(SIMULATED_CONSTANT.COMPONENT_MODAL_SUCCESS);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao enviar simulado.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingButtonSend = false;
    }
  }

  clickLeftButtonModalRedaction() {
    this.showedRedactionModal = true;
    this.verifyRedactionAndQuestionsBeforeSendSimulated();
  }

  clickRightButtonModalRedaction() {
    const indexTabRedaction = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === SIMULATED_CONSTANT.TAB_ID_REDACTION);

    this.setCurrentlyActiveTab(indexTabRedaction);

    this.handleActiveTabSwitching(indexTabRedaction, this.tabMenu);
  }

  clickButtonFinishModalQuestion() {
    this.showedRedactionModal = true;

    this.sendSimulated();
    this.disableModal('modalWarningQuestion');
  }

  clickLeftButtonModalQuestion() {
    this.showedRedactionModal = false;
  }

  setStartTimeCount() {
    const minutesCounter = this.infoSimulatedQuestion[this.indexStoreSimulatedDay]?.duration;
    this.secondsCounter = minutesCounter * 60;
  }

  @Watch('secondsCounter')
  timeCounter() {
    if (this.secondsCounter === 0) {
      this.showModal(SIMULATED_CONSTANT.COMPONENT_MODAL_FINISHED_TIME);
      return;
    }

    setTimeout(() => {
      this.secondsCounter -= 1;
    }, 1000);

    const formattedTime = moment.utc(this.secondsCounter * 1000).format('HH:mm:ss');
    this.clock = formattedTime;
  }

  showModal(idModal: string) {
    this.setModal.activeModal(idModal);
  }

  verifyRedactionAndQuestionsBeforeSendSimulated() {
    let isApprovedToSend;

    if (!this.showedRedactionModal) isApprovedToSend = this.verifyRedactionContent() && this.verifyAnsweredQuestions();
    else {
      this.showedRedactionModal = false;
      isApprovedToSend = this.verifyAnsweredQuestions();
    }

    if (isApprovedToSend) this.sendSimulated();
  }

  verifyRedactionContent() {
    if (this.hasRedactionInSimulatedDay) {
      const hasRedaction = !!this.redaction.content || !!this.redaction.URL;
      if (!hasRedaction) this.showModal(SIMULATED_CONSTANT.COMPONENT_MODAL_WARNING_REDACTION);
      return hasRedaction;
    }
    return true;
  }

  verifyAnsweredQuestions() {
    const isAnsweredQuestions = this.answerQuestions.length === this.totalQuantityQuestions;

    if (!isAnsweredQuestions) this.showModal(SIMULATED_CONSTANT.COMPONENT_MODAL_WARNING_QUESTION);
    return isAnsweredQuestions;
  }

  getInputRedaction(receivedRedaction: IReceivedRedaction) {
    this.redaction = {
      themeID: this.themeIdRedaction,
      [receivedRedaction.type]: UrlTreatmentSaveVersa(receivedRedaction.content),
    };
  }

  setAnswerQuestion(data: IAnsweredQuestion, indexQuestionCurrent: number) {
    const indexQuestionAnswered = this.answerQuestions.findIndex((question: IAnsweredQuestion) => question.question_id === data.question_id);

    if (indexQuestionAnswered !== -1) {
      this.answerQuestions[indexQuestionAnswered] = data;
    } else {
      this.answerQuestions.push(data);
    }
    this.setOptionSelected(data, indexQuestionCurrent);
  }

  setOptionSelected(dataAnsweredQuestion: IAnsweredQuestion, indexQuestionCurrent: number) {
    this.newQuestions[this.tabActive] = this.newQuestions[this.tabActive].map((question: INewQuestionSimulated, indexQuestion: number) => {
      const newListOption = this.newListOptions(question, dataAnsweredQuestion);

      return this.setNewQuestionListStatus(question, dataAnsweredQuestion, newListOption, indexQuestionCurrent, indexQuestion);
    });
  }

  newListOptions(question: INewQuestionSimulated, dataAnsweredQuestion: IAnsweredQuestion) {
    const newListOption = question.options.map((option: INewOption) => {
      if (dataAnsweredQuestion.option_id === option.ID) {
        return {
          ...option,
          value: true,
        };
      } if (dataAnsweredQuestion.option_id !== option.ID && option.value) {
        return {
          ...option,
          value: false,
        };
      }
      return option;
    });
    return newListOption;
  }

  setNewQuestionListStatus(
    question: INewQuestionSimulated,
    answeredQuestion: IAnsweredQuestion,
    newListOption: Array<INewOption>,
    indexQuestionCurrent: number,
    indexQuestion: number,
  ) {
    if (question.id === answeredQuestion.question_id) {
      return {
        ...question,
        response: SIMULATED_CONSTANT.QUESTION_ANSWERED,
        options: newListOption,
      };
    }
    if (indexQuestion < indexQuestionCurrent && !question.response) {
      return {
        ...question,
        response: SIMULATED_CONSTANT.QUESTION_SKIP,
        options: newListOption,
      };
    }
    return question;
  }

  setCurrentlyActiveTab(indexTab: number) {
    this.tabMenu = this.tabMenu.map((tab: TabsMenuInterface, index: number) => {
      if (index === indexTab) return { ...tab, active: true };
      return { ...tab, active: false };
    });
  }

  setNextTab() {
    const indexNextTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive) + SIMULATED_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexNextTab);

    if (indexNextTab !== -1) this.handleActiveTabSwitching(indexNextTab, this.tabMenu);
  }

  setPreviousTab() {
    const indexPreviousTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive) - SIMULATED_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexPreviousTab);

    if (indexPreviousTab !== -1) this.handleActiveTabSwitching(indexPreviousTab, this.tabMenu);
  }

  handleActiveTabSwitching(indexTabSelected: number, tabData: Array<TabsMenuInterface>) {
    this.tabMenu = tabData;
    this.tabActiveIsRedaction = tabData[indexTabSelected].id === SIMULATED_CONSTANT.TAB_ID_REDACTION;
    this.tabActive = tabData[indexTabSelected].id || '';
  }

  @Watch('tabActiveIsRedaction')
  changeActiveTabComponent() {
    if (this.tabActiveIsRedaction) {
      this.selectComponent = this.isOpenComponentsInputRedaction ? SIMULATED_CONSTANT.COMPONENT_SEND_REDACTION : SIMULATED_CONSTANT.COMPONENT_REDACTION_INSTRUCTION;
    } else this.selectComponent = SIMULATED_CONSTANT.COMPONENT_QUESTION;
  }

  @Watch('isShowAllQuestion')
  @Watch('tabActiveIsRedaction')
  setScrollPage() {
    if (this.isShowAllQuestion || this.tabActiveIsRedaction) this.setScroll.scrollTopPage();
  }

  toGoBackInstructionRedaction() {
    this.selectComponent = SIMULATED_CONSTANT.COMPONENT_REDACTION_INSTRUCTION;
    this.isOpenComponentsInputRedaction = false;
  }

  openComponentInputRedaction() {
    this.selectComponent = SIMULATED_CONSTANT.COMPONENT_SEND_REDACTION;
    this.isOpenComponentsInputRedaction = true;
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([{ title: 'Simulado ', to: null }]);
  }

  handleClickToViewResult() {
    this.disableModal('ModalSuccessSimulated');

    this.$router.push({
      name: SIMULATED_CONSTANT.NAME_ROUTE_RESULT,
      params: {
        id: this.$route.params.id,
      },
    });

    this.$store.commit('setPermanenceSimulatedData', true);
  }

  disableModal(idModal: string) {
    if (!idModal) return;

    this.setModal.disableModal(idModal);
  }
}
